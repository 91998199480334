const data = [
  {
    name: "Dr. R.R. Rathod",
    position: "HOD IT DEPT.",
    photo: "https://res.cloudinary.com/dptdrzycj/image/upload/v1704457608/R_R_Rathod_HoD_IT1_ufacjb.jpg",
  },
  {
    name: "Prof. M.B. Narnaware",
    position: "Staff Advsior",
    photo: "https://res.cloudinary.com/dptdrzycj/image/upload/v1704457639/WhatsApp_Image_2024-01-05_at_14.51.33_wjxean.jpg",
  },
  {
    name: "Prof. M.G. Rathi",
    position: "Staff Advsior",
    photo: "",
  },
];

export default data;